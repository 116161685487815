import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import makeStyles from '@material-ui/core/styles/makeStyles';

import CmtVertical from '../../../../../@coremat/CmtNavigation/Vertical';
import { sidebarNavs } from '../menus';
import { getQueryParams, getQueryString } from 'routes/queryFunctions';

import { Assessment, VpnKey } from '@material-ui/icons';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { useSelector } from 'react-redux';
import { addDeleteDirectorsMenuOption, addDeleteCopytradingMenuOption } from './functions';

const useStyles = makeStyles(() => ({
  perfectScrollbarSidebar: {
    transition: 'all 0.3s ease',
    '.Cmt-sidebar-fixed &, .Cmt-Drawer-container &': {
      height: 2000,
    },
    '.Cmt-modernLayout &': {
      height: 'calc(100% - 72px)',
    },
    '.Cmt-miniLayout &': {
      height: 'calc(100% - 91px)',
    },
    '.Cmt-miniLayout .Cmt-sidebar-content:hover &': {
      height: 'calc(100% - 167px)',
    },
  },
}));

const SideBar = () => {
  const classes = useStyles();

  const { authUser } = useSelector(({ auth }) => auth);

  // Set query params to url start
  const location = useLocation();
  const search = location.search;
  let restrict = ['ref'];
  const queryParams = getQueryParams(search, restrict);
  const [queryString, setQuerystring] = useState(getQueryString(queryParams));

  useEffect(() => {
    const asyncFunction = async () => {
      const directorsPageName = <IntlMessages id={'pages.directorsPage'} />;
      const directorsPageIcon = <Assessment />;
      await addDeleteDirectorsMenuOption(sidebarNavs[0].children, authUser, directorsPageName, directorsPageIcon);
      
      const copytradingPageName = <IntlMessages id={'pages.copytradingPage'} />;
      const copytradingPageIcon = <VpnKey />;
      const copytradingStrategy1Name = <IntlMessages id={'pages.copytrading.strategy1'} />;
      const copytradingStrategy2Name = <IntlMessages id={'pages.copytrading.strategy2'} />;
      await addDeleteCopytradingMenuOption(sidebarNavs[0].children, authUser.userID, copytradingPageName, copytradingPageIcon, copytradingStrategy1Name, copytradingStrategy2Name);
    }
    asyncFunction();
  }, []);

  let paramsToRemove = localStorage.getItem('params_to_remove');
  if (paramsToRemove) {
    let newRestrict = restrict;
    paramsToRemove = JSON.parse(paramsToRemove);
    paramsToRemove.forEach(paramToRemove => {
      newRestrict.push(paramToRemove);
    });
    localStorage.removeItem('params_to_remove');

    const queryParams = getQueryParams(search, newRestrict);
    const queryString = getQueryString(queryParams);
    localStorage.setItem('link_on_reload', JSON.stringify(queryString));
    setQuerystring(queryString);
  }
  sidebarNavs[0].children.forEach(child => {
    const index = child.link.indexOf('?');
    if (index !== -1) {
      child.link = child.link.slice(0, index);
    }
    child.link += queryString;
  });
  // Set query params to url finish

  return (
    <PerfectScrollbar className={classes.perfectScrollbarSidebar}>
      <CmtVertical menuItems={sidebarNavs} />
    </PerfectScrollbar>
  );
};

export default SideBar;
