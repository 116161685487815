import React from 'react';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import Modal from 'react-modal';
import { Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { fetchSuccess } from '../../redux/actions';
import calculateSideMarginsToCenterModal from 'components/Functions/calculateSideMarginsToCenterModal';
import IntlMessages from '@jumbo/utils/IntlMessages';
import getCopytradingStrategyLink from 'components/Functions/getCopytradingStrategyLink';

const PurchaseConfirmationModal = ({
  setIsOpen,
  modalIsOpen,
  transactionHash,
  PID,
  setCouponIsOpen,
  couponLoaded,
  copytradingStrategy,
}) => {
  const screenWidth = window.innerWidth;
  const dispatch = useDispatch();
  const modalStyles = {
    content: {
      position: screenWidth > 910 ? 'relative' : 'none',
      zIndex: 1,
      marginTop: 120,
      marginRight: calculateSideMarginsToCenterModal(800).marginRight,
      marginLeft: calculateSideMarginsToCenterModal(800).marginLeft,
      maxHeight: 500,
      width: 800,
      maxWidth: '100%',
      overflowY: 'scroll',
    },
  };
  const contentStyles = {
    display: 'flex',
    flexDirection: 'column',
    height: 300,
    justifyContent: 'space-around',
  };

  const handleModalClose = () => {
    setIsOpen(false);
    if (couponLoaded) {
      setCouponIsOpen(true);
    }
    if (copytradingStrategy) {
      window.location.reload();
    }
  };

  const generateCopyData = () => {
    let copyData = `Link: ${process.env.REACT_APP_BSCSCAN_LINK}${transactionHash}\nPurchaseID: ${PID}`;
    return copyData;
  };

  return (
    <Modal
      style={modalStyles}
      isOpen={modalIsOpen}
      contentLabel={'Purchase Confirmation Modal'}
      onRequestClose={handleModalClose}>
      <Button onClick={handleModalClose}>
        <IntlMessages id={'component.close'} />
      </Button>
      <CmtCardContent style={contentStyles}>
        <h2>
          <IntlMessages id={'component.purchasedPackage'} />
        </h2>
        {PID && (
          <p>
            <IntlMessages id={'component.purchaseID'} />: {PID}
          </p>
        )}
        {transactionHash && (
          <p>
            <IntlMessages id={'component.linkToBscScan'} />
            {': '}
            <a href={`${process.env.REACT_APP_BSCSCAN_LINK}${transactionHash}`} target="_blank" rel="noreferrer">
              <IntlMessages id={'component.purchaseConfirmationModal.string2'} />
            </a>
          </p>
        )}
        {copytradingStrategy && (
          <div>
            <h2>
              <IntlMessages id={'component.purchaseConfirmationModal.string4'} />
            </h2>
            <h4>
              <a
                href={getCopytradingStrategyLink(copytradingStrategy)}
                target="_blank"
                rel="noreferrer">
                <IntlMessages id={'component.purchaseConfirmationModal.string5'} />
              </a>
            </h4>
            <h4>
              <IntlMessages id={'component.purchaseConfirmationModal.string6'} />
            </h4>
            <p>
              <i><IntlMessages id={'component.purchaseConfirmationModal.string7'} /></i>
            </p>
            <h4>
              <IntlMessages id={'component.purchaseConfirmationModal.string8'} />
            </h4>
          </div>
        )}
        <Button
          onClick={() => {
            const copyData = generateCopyData();
            navigator.clipboard.writeText(copyData);
            dispatch(fetchSuccess(<IntlMessages id={'component.copied'} />));
          }}>
          <IntlMessages id={'component.copyInformation'} />
        </Button>
      </CmtCardContent>
    </Modal>
  );
};

export default PurchaseConfirmationModal;
