import React, { useState, useEffect } from 'react';
import PackageDesktop from './PackageDesktop';
import PackageMobile from './PackageMobile';
import ExpiresWidget from 'components/Common/ExpiresWidget/ExpiresWidget';
import moment from 'moment';

const Package = ({
  title,
  description,
  languages,
  duration,
  enabled,
  packageID,
  packagePrice,
  rebuyEnabled,
  purchasesCount,
  lastTimestamp,
  availableUnits,
  disablePWD,
  disableBUSD,
  couponKeyword,
  couponExpiration,
  customIcon,
  userAddress,
  userEmail,
  setTransactionHash,
  setPID,
  setCoupon,
  setCouponSetting,
  setCouponLoaded,
  setCopytradingStrategy,
  setPurchaseConfirmationIsOpen,
  isDisabled,
}) => {
  const [purchaseEnabled, setPurchaseEnabled] = useState(true);
  const [expirationTimestamp, setExpirationTimestamp] = useState(undefined);
  const screenWidth = window.innerWidth;

  useEffect(() => {
    if (availableUnits == 0 || !enabled) {
      setPurchaseEnabled(false);
    }
    if (purchasesCount > 0 && !rebuyEnabled) {
      setPurchaseEnabled(false);
    }
    if (couponExpiration) {
      const expirationMoment = moment(couponExpiration * 1000);
      const now = moment();
      if (expirationMoment.isBefore(now)) {
        setPurchaseEnabled(false);
      }
    }

    refreshExpiration();
  }, [availableUnits, packageID, purchasesCount]);

  const refreshExpiration = () => {
    if (lastTimestamp && duration > 0) {
      const timestampInSeconds = Number(lastTimestamp) + Number(duration);
      const timestampInMilliseconds = timestampInSeconds * 1000; // Convert to milliseconds

      setExpirationTimestamp(timestampInMilliseconds);
    }
  };

  const props = {
    isDisabled,
    title,
    description,
    languages,
    duration,
    packageID,
    purchasesCount,
    lastTimestamp,
    availableUnits,
    disablePWD,
    disableBUSD,
    purchaseEnabled,
    packagePrice,
    packageID,
    userAddress,
    couponKeyword,
    setCoupon,
    setTransactionHash,
    setPID,
    userEmail,
    setCouponSetting,
    setCouponLoaded,
    setCopytradingStrategy,
    rebuyEnabled,
    setPurchaseEnabled,
    setPurchaseConfirmationIsOpen,
    expirationTimestamp,
    setExpirationTimestamp,
    ExpiresWidget,
    customIcon,
  };
  if (screenWidth > 856) {
    return <PackageDesktop props={props} />;
  } else {
    return <PackageMobile props={props} />;
  }
};

export default Package;
