import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchStart, fetchSuccess, fetchError } from 'redux/actions';
import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import web3 from 'web3';
import { isPWD, setDisplayPercentageDecimal } from './functions';
import { getParsedConfiguredDiscounts } from 'smart_contract/services';
import LevelDetailedInfoModal from './LevelDetailedInfoModal';
import QuantityDetailedInfoModal from './QuantityDetailedInfoModal';
import IconModal from 'components/Common/IconModal';
import PurchaseButtons from 'components/Common/PurchaseButtons/PurchaseButtons';
import Description from 'components/Common/Description';
import './styles.css'
const imagesFolder = '/images/packages';
const defaultIcon = 'package.svg';
const defaultPaymentIcon = 'money_pay.png';

const PackageMobile = ({ props }) => {
  const {
    isDisabled,
    title,
    description,
    languages,
    duration,
    purchasesCount,
    availableUnits,
    disablePWD,
    disableBUSD,
    purchaseEnabled,
    packagePrice,
    packageID,
    userAddress,
    couponKeyword,
    setCoupon,
    setTransactionHash,
    setPID,
    userEmail,
    setCouponSetting,
    setCouponLoaded,
    setCopytradingStrategy,
    rebuyEnabled,
    setPurchaseConfirmationIsOpen,
    setPurchaseEnabled,
    expirationTimestamp,
    setExpirationTimestamp,
    ExpiresWidget,
    customIcon,
  } = props;
  const pwdOnly = disableBUSD && !disablePWD;

  const modalStyles = {
    content: {
      position: 'none',
      zIndex: 1,
      marginTop: 120,
      overflow: 'hidden',
      width: '100%',
      height: '100%',
      minHeight: 300,
    },
    body: {
      withData: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        alignItems: 'center',
        textAlign: 'center',
        width: '100%',
      },
      noData: {
        textAlign: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#7a7b7d',
        width: '100%',
      },
      iconModal: {
        textAlign: 'center',
        display: 'flex',
        height: '70%',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#7a7b7d',
        width: '100%',
      },
    },
    tableDiv: {
      width: '100%',
      overflow: 'scroll',
      marginTop: 20,
      borderRight: 'black 2px solid',
      table: {
        minWidth: 500,
        border: '2px solid black',
        borderRight: 0,
        borderCollapse: 'collapse',
        td: {
          height: 50,
          border: '2px solid black',
          borderRight: 0,
          borderCollapse: 'collapse',
          div: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
        },
        textSmall: {
          fontSize: 10,
        },
        logo: {
          width: 25,
          height: 25,
          marginRight: 5,
        },
        currentLevel: {
          backgroundColor: '#f7cb78',
        },
      },
    },
  };

  const iconStyles = {
    small: {
      height: 35,
      width: 35,
      marginRight: 10,
    },
    large: {
      height: 55,
      width: 55,
      marginRight: 10,
    },
  };

  const dispatch = useDispatch();

  const [loadModals, setLoadModals] = useState(false);
  const [levelModalOpen, setLevelModalOpen] = useState(false);
  const [quantityModalOpen, setQuantityModalOpen] = useState(false);
  const [iconModalOpen, setIconModalOpen] = useState(false);
  const [configuredDiscounts, setConfiguredDiscounts] = useState({});
  const [cursor, setCursor] = useState('default');
  const [token, setToken] = useState('busd');
  const [price, setPrice] = useState(packagePrice);
  const [discountsInfos, setDiscountsInfos] = useState({});
  const [paymentIcon, setPaymentIcon] = useState(defaultPaymentIcon);
  const [displayBUSDinPrice, setDisplayBUSDinPrice] = useState(false);
  const [useBackgroundImage, setUseBackgroundImage] = useState(false);

  useEffect(() => {
    setLoadModals(true);
    if (pwdOnly) {
      setPaymentIcon('pwd_token.png');
    }
  }, []);

  const onModalOpen = async type => {
    dispatch(fetchStart());

    let setModalOpen;
    if (type === 'level') {
      setModalOpen = setLevelModalOpen;
    } else if (type === 'quantity') {
      setModalOpen = setQuantityModalOpen;
    }

    const request = await getParsedConfiguredDiscounts(packageID);
    if (request.error) {
      dispatch(fetchError(request.error));
      return;
    }
    setConfiguredDiscounts(request.parsedConfiguredDiscounts);
    dispatch(fetchSuccess());
    setModalOpen(true);
  };

  const onIconModalOpen = () => {
    setIconModalOpen(true);
  };

  const languageFlags = languages.map(language => {
    return (
      <img style={{ width: 30, margin: '0 5px', }} key={language} src={`/images/flag/packages/${language}.svg`} />
    )
  });

  return (
    <CmtCard
      id={`package_element_${packageID}`}
      style={{
        background: useBackgroundImage ? 'url(/images/gray_bg.jpg)' : null,
        backgroundSize: useBackgroundImage ? 'cover' : null,
        marginBottom: '20px',
        opacity: isDisabled ? '60%' : null,
      }}>
        <div style={{ display: 'flex', position: 'absolute', top: 10, right: 8 }}>
          {languageFlags}
        </div>
      {loadModals && customIcon && (
        <IconModal
          modalStyles={modalStyles}
          setIsOpen={setIconModalOpen}
          modalIsOpen={iconModalOpen}
          appElement={`#package_element_${packageID}`}
          icon={customIcon}
        />
      )}
      {loadModals && (
        <div>
          <LevelDetailedInfoModal
            modalStyles={modalStyles}
            setIsOpen={setLevelModalOpen}
            modalIsOpen={levelModalOpen}
            packageID={packageID}
            configuredDiscounts={configuredDiscounts}
            currentIndex={discountsInfos.index}
          />
          <QuantityDetailedInfoModal
            modalStyles={modalStyles}
            setIsOpen={setQuantityModalOpen}
            modalIsOpen={quantityModalOpen}
            packageID={packageID}
            configuredDiscounts={configuredDiscounts}
          />
        </div>
      )}
      <CmtCardHeader
        style={{ zIndex: 0 }}
        title={title ? title : 'No title'}
        subTitle={`ID: ${packageID}`}
        icon={<img src={customIcon ? customIcon : `${imagesFolder}/${defaultIcon}`} style={{ height: 90 }} alt="" />}
        onIconModalOpen={customIcon ? onIconModalOpen : undefined}
        setCursor={customIcon ? setCursor : undefined}
        cursor={customIcon ? cursor : 'default'}
      />
      {isPWD(token, discountsInfos) && (
        <CmtCardContent
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
            whiteSpace: 'nowrap',
            zIndex: 0,
          }}>
          <img style={iconStyles.small} src={`${imagesFolder}/price_label.png`} alt="" />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <p>
              {Number(web3.utils.fromWei(discountsInfos.priceBUSD)).toFixed(2)} <i>$BUSD</i>
            </p>
            <p>
              ( ~{Number(web3.utils.fromWei(discountsInfos.pricePWD)).toFixed(2)} <i>PWD</i> )
            </p>
          </div>
        </CmtCardContent>
      )}
      <CmtCardContent style={{ zIndex: 0, paddingTop: 0, paddingBottom: 0 }}>
        <Description description={description} />
      </CmtCardContent>
      <CmtCardContent style={{ paddingBottom: 0, zIndex: 0 }}>
        <div style={{ justifyContent: 'center', zIndex: 0, whiteSpace: 'nowrap' }}>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
            <img style={iconStyles.large} src={`${imagesFolder}/${paymentIcon}`} alt="" />
            {isPWD(token, discountsInfos) ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}>
                <h2>
                  ~{Number(web3.utils.fromWei(discountsInfos.discountedPricePWD)).toFixed(2)} <i>PWD</i>
                </h2>
                <h3>
                  ( {Number(web3.utils.fromWei(discountsInfos.discountedPriceBUSD)).toFixed(2)} <i>USDT</i> )
                </h3>
              </div>
            ) : (
              <h2>
                {Number(web3.utils.fromWei(price)).toFixed(2)} <i>${displayBUSDinPrice ? 'BUSD' : null}</i>
              </h2>
            )}
          </div>
          {isPWD(token, discountsInfos) && (
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 30 }}>
              <img style={iconStyles.large} src={`${imagesFolder}/discounted_label.png`} alt="" />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}>
                <h3 style={{ fontWeight: 'bold' }}>TOTAL DISCOUNT</h3>
                <h3>
                  <span style={{ fontWeight: 'bold' }}>{setDisplayPercentageDecimal(discountsInfos.totalPercentage)}</span>
                  <span style={{ fontWeight: 'lighter' }}> %</span>
                </h3>
              </div>
            </div>
          )}
        </div>
        {isPWD(token, discountsInfos) && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              whiteSpace: 'nowrap',
            }}>
            <div
              style={{
                display: 'flex',
                cursor,
                alignItems: 'center',
                marginRight: 15,
              }}
              onClick={() => onModalOpen('level')}
              onMouseOver={() => setCursor('pointer')}>
              <img style={iconStyles.small} src={`${imagesFolder}/levels_discount.png`} alt="" />
              <div>
                <p>
                  <span style={{ fontWeight: 'lighter' }}>LEVEL</span>
                  <span style={{ fontWeight: 'bold' }}> {discountsInfos.level}</span>
                </p>
                <p>
                  <span style={{ fontWeight: 'bold' }}>{setDisplayPercentageDecimal(discountsInfos.discountLevel)}</span>
                  <span style={{ fontWeight: 'ligher' }}> %</span>
                </p>
              </div>
            </div>
            <div
              style={{ display: 'flex', cursor, alignItems: 'center' }}
              onClick={() => onModalOpen('quantity')}
              onMouseOver={() => setCursor('pointer')}>
              <img style={iconStyles.small} src={`${imagesFolder}/stack_discount.png`} alt="" />
              <p>
                <span style={{ fontWeight: 'bold' }}>{setDisplayPercentageDecimal(discountsInfos.quantityDiscount)}</span>
                <span style={{ fontWeight: 'lighter' }}> %</span>
              </p>
            </div>
          </div>
        )}
      </CmtCardContent>
      <CmtCardContent style={{ zIndex: 0, marginTop: 10 }}>
        {availableUnits != -1 && <p style={{ marginBottom: 10 }}>Available: {availableUnits}</p>}
        <PurchaseButtons
          purchaseEnabled={purchaseEnabled}
          purchasesCount={purchasesCount}
          disableBUSD={disableBUSD}
          disablePWD={disablePWD}
          token={token}
          setToken={setToken}
          setDiscountsInfos={setDiscountsInfos}
          setPrice={setPrice}
          packagePrice={packagePrice}
          packageID={packageID}
          userAddress={userAddress}
          price={price}
          couponKeyword={couponKeyword}
          setCoupon={setCoupon}
          setTransactionHash={setTransactionHash}
          setPID={setPID}
          userEmail={userEmail}
          setCouponSetting={setCouponSetting}
          setCouponLoaded={setCouponLoaded}
          setCopytradingStrategy={setCopytradingStrategy}
          rebuyEnabled={rebuyEnabled}
          setPurchaseEnabled={setPurchaseEnabled}
          setPurchaseConfirmationIsOpen={setPurchaseConfirmationIsOpen}
          setExpirationTimestamp={setExpirationTimestamp}
          setPaymentIcon={setPaymentIcon}
          setDisplayBUSDinPrice={setDisplayBUSDinPrice}
          setUseBackgroundImage={setUseBackgroundImage}
        />
        {purchasesCount > 0 && duration > 0 && expirationTimestamp ? (
          <div style={{ marginTop: 25 }}>
            <ExpiresWidget expirationTimestamp={expirationTimestamp} />
          </div>
        ) : null}
      </CmtCardContent>
    </CmtCard>
  );
};

export default PackageMobile;
