const getCopytradingStrategyLink = strategyName => {
  const links = {
    'powermade1-0': 'https://secure.keytomarkets.com/links/go/5060',
    'powermade2-0': 'https://secure.keytomarkets.com/links/go/5801',
  }

  return links[strategyName];
};

export default getCopytradingStrategyLink;
